<!--
 * @Author: your name
 * @Date: 2022-03-30 17:53:44
 * @LastEditTime: 2022-03-30 18:12:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nbh_gys_acadmin\src\views\store\components\AppealTable.vue
-->
<template>
  <div>
    <el-table
      v-loading="tbLoad"
      :data="tbList"
      border
      style="width: 100%"
      highlight-current-row
      :header-cell-style="{
        background: '#D9DDE1',
        color: '#0D0202',
        'font-weight': 400,
        fontSize: '12px',
      }"
      row-key="id"
    >
      <el-table-column
        align="center"
        prop="id"
        label="申诉ID"
      />
      <el-table-column
        align="center"
        prop="approveType"
        label="申诉类型"
      >
        <template slot-scope="scope">
          {{
            appealTypeDic[scope.row.approveType]
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="violationItem"
        label="违规项目"
      />
      <el-table-column
        align="center"
        prop="violationName"
        label="违规场景"
      />
      <el-table-column
        min-width="200"
        align="center"
        prop="description"
        label="定义"
      />
      <el-table-column
        align="center"
        prop="transtionDescription"
        :label="code === '3300' ? '转码规则' : '转标规则'"
      />

      <el-table-column
        align="center"
        prop="violationAlarmLevel"
        label="违规等级"
      >
        <template slot-scope="scope">
          {{ colorDic[scope.row.violationAlarmLevel] }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="status"
        label="状态"
      >
        <template slot-scope="scope">
          {{ appealStatusDic[scope.row.status] }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="_showWarnDetailModal(scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="page.current"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <!-- 申诉弹框 -->
    <AppealModal
      v-model="showAppealModal"
      :row="currentRow"
      :type="appealType"
    />
  </div>
</template>

<script>
import AppealModal from './AppealModal.vue';
import Page from '@/components/common/Page.vue';

export default {
  components: {
    Page,
    AppealModal,
  },
  props: {
    code: {
      type: String,
      default: '3300',
    },
  },
  data() {
    return {
      tbLoad: false,
      tbList: [],
      colorDic: {
        0: '绿色',
        1: '红色',
        3: '黄色',
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      appealTypeDic: this.code === '3300' ? {
        1: '违规申诉',
        2: '申请黄码转绿码',
        3: '申请红码转绿码',
        4: '申请绿码转红码',
        5: '申请黄码转红码',
      } : {
        1: '违规申诉',
        2: '申请黄标转绿标',
        3: '申请红标转绿标',
        4: '申请绿标转红标',
        5: '申请黄标转红标',
      },
      appealStatusDic: {
        1: '平台待审核',
        2: '平台已通过',
        3: '平台已驳回',
        4: '待主管部门审核',
        5: '主管部门驳回',
        6: '主管部门已通过',
      },
      currentRow: null,
      showAppealModal: false,
      appealType: 0,
    };
  },
  mounted() {
    this.getList(true);
  },
  methods: {
    // 申诉列表
    getList(isInit = false) {
      this.tbLoad = true;
      if (isInit) this.page.current = 1;

      const pars = {
        approveId: '',
        approveType: '',
        currentPage: this.page.current,
        endTime: '',
        mobile: '',
        pageSize: this.page.size,
        startTime: '',
        status: '',
        supplierId: '',
        suppliersName: '',
        violationAlarmLevel: '',
        violationDetailNo: '',
        violationItem: '',
      };

      this.$axios.post(this.$api.storeWarn.appealList, pars).then((res) => {
        const {
          current, total, size, records,
        } = res.data;
        if (res.code === 0) {
          this.page = {
            current,
            total,
            size,
          };
          this.tbList = records;
        } else {
          this.$message.error(res.msg);
        }
        this.tbLoad = false;
      });
    },
    _showWarnDetailModal(row) {
      this.currentRow = row;
      this.appealType = 1;
      this.showAppealModal = true;
    },
    handleSizeChange(s) {
      this.page.size = s;
      this.getList();
    },
    handleCurrentChange(p) {
      this.page.current = p;
      this.getList();
    },
  },
};
</script>

<style>
</style>
