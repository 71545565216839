var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "违规申诉",
        visible: _vm.value,
        width: "60%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.value = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _vm.showIllegalId
            ? _c(
                "el-form-item",
                { attrs: { prop: "violationDetailNo", label: "申诉违规ID" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.type == 1 },
                    model: {
                      value: _vm.form.violationDetailNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "violationDetailNo", $$v)
                      },
                      expression: "form.violationDetailNo",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "content", label: "申诉描述" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  disabled: _vm.type == 1,
                  rows: 2,
                  placeholder: "请输入内容",
                },
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "imageUrls", label: "申诉材料" } },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    disabled: _vm.type == 1,
                    "on-success": _vm.uploadSuc,
                    "file-list": _vm.uploadList,
                    accept: "image/*",
                    name: "multipartFile",
                    action: _vm.$api.nb_common.newUpload,
                    "list-type": "picture-card",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "file",
                      fn: function ({ file }) {
                        return _c("div", {}, [
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: { src: file.url, alt: "" },
                          }),
                        ])
                      },
                    },
                  ]),
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { slot: "default" },
                    slot: "default",
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "mobile", label: "联系方式" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.type == 1, placeholder: "请输入内容" },
                model: {
                  value: _vm.form.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mobile", $$v)
                  },
                  expression: "form.mobile",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("input", false)
                },
              },
            },
            [_vm._v(" 取消 ")]
          ),
          _vm.type == 0
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.saveLoad, type: "primary" },
                  on: { click: _vm.confirm },
                },
                [_vm._v(" 提交 ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }