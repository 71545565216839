var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tbLoad,
              expression: "tbLoad",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tbList,
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "#D9DDE1",
              color: "#0D0202",
              "font-weight": 400,
              fontSize: "12px",
            },
            "row-key": "id",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", prop: "id", label: "申诉ID" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "approveType", label: "申诉类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.appealTypeDic[scope.row.approveType]) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "violationItem",
              label: "违规项目",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "violationName",
              label: "违规场景",
            },
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "200",
              align: "center",
              prop: "description",
              label: "定义",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "transtionDescription",
              label: _vm.code === "3300" ? "转码规则" : "转标规则",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "violationAlarmLevel",
              label: "违规等级",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.colorDic[scope.row.violationAlarmLevel]) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "status", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.appealStatusDic[scope.row.status]) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm._showWarnDetailModal(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 详情 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.page.current,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _vm.page.size,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.page.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("AppealModal", {
        attrs: { row: _vm.currentRow, type: _vm.appealType },
        model: {
          value: _vm.showAppealModal,
          callback: function ($$v) {
            _vm.showAppealModal = $$v
          },
          expression: "showAppealModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }