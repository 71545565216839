<template>
  <div class="warn">
    <div
      v-if="userInfo.qrCodeColor != -1"
      class="nav"
    >
      <el-alert
        class="tip"
        effect="dark"
        :closable="false"
        :title="
          userInfo.qrCodeColor == 1
            ? '您的店铺存在严重违规行为,现已下架处理，请尽快完成整改，申请重新上架'
            : userInfo.qrCodeColor == 0
              ? '正常运营'
              : '您的店铺存在违规行为请尽快完成整改'
        "
        :type="
          userInfo.qrCodeColor == 0
            ? 'success'
            : userInfo.qrCodeColor == 1
              ? 'error'
              : 'warning'
        "
        show-icon
      />
      <el-row
        type="flex"
        align="middle"
        :gutter="20"
      >
        <el-col :span="3">
          <div
            class="tr"
            style="font-size: 15px; font-weight: bold"
          >
            {{ projectCode === '3300' ? '当前店铺码:' : '当前店铺标识:' }}
          </div>
        </el-col>
        <el-col :span="6">
          <div v-if="projectCode === '3100'">
            <img
              v-if="userInfo.qrCodeColor === 0"
              class="code-img"
              src="https://nb-img.hzanchu.com/h5/nbh_admin/img/20231108113740.png"
              alt=""
            />
            <img
              v-if="userInfo.qrCodeColor === 3"
              class="code-img"
              src="https://nb-img.hzanchu.com/h5/nbh_admin/img/20231108113730.png"
              alt=""
            />
            <img
              v-if="userInfo.qrCodeColor === 1"
              class="code-img"
              src="https://nb-img.hzanchu.com/h5/nbh_admin/img/20231108113750.png"
              alt=""
            />
          </div>
          <div v-if="projectCode === '5100'">
            <img
              v-if="userInfo.qrCodeColor === 0"
              class="code-img"
              src="https://oss.zjnongbo.com/common/images/ui/sansema-jiankang.png"
              alt=""
            />
            <img
              v-if="userInfo.qrCodeColor === 3"
              class="code-img"
              src="https://oss.zjnongbo.com/common/images/ui/sansema-jinggao.png"
              alt=""
            />
            <img
              v-if="userInfo.qrCodeColor === 1"
              class="code-img"
              src="https://oss.zjnongbo.com/common/images/ui/sansema-yichang.png"
              alt=""
            />
          </div>
          <img
            v-else
            class="code-img"
            :src="qrCode"
            alt=""
          />
        </el-col>
      </el-row>
    </div>

    <div style="margin: 20px 0">
      <el-radio-group
        v-model="tbType"
        size="medium"
      >
        <el-radio-button label="0">
          违规情况
        </el-radio-button>
        <el-radio-button label="1">
          申诉情况
        </el-radio-button>
      </el-radio-group>
    </div>

    <!-- 违规表格 -->
    <IllegalTable
      v-show="tbType == 0"
      :code="projectCode"
      @confirm="confirm"
    />
    <AppealTable
      v-show="tbType == 1"
      ref="appealTb"
      :code="projectCode"
    />
  </div>
</template>

<script>
import IllegalTable from '../components/IllegalTable.vue';
import AppealTable from '../components/AppealTable.vue';

export default {
  components: {
    AppealTable,
    IllegalTable,
  },
  data() {
    return {
      projectCode: localStorage.getItem('projectCode'),
      tbLoad: false,
      qrCode: '',
      userInfo: {},
      tbType: '0',
    };
  },
  created() {
  },
  mounted() {
    // 获取店铺吗

    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.$axios({
        method: 'post',
        url: this.$api.back_supplier.view,
      }).then((res) => {
        if (res.code === 0) {
          this.userInfo = res.data;
          if (this.projectCode === '3300') {
            this.getQrCode();
          }
        }
      });
    },
    getQrCode() {
      const pars = {
        supplierId: this.userInfo.suppliersId,
      };
      this.$axios
        .get(this.$api.storeWarn.getCode, { params: pars })
        .then((res) => {
          if (res.code === 0) {
            this.qrCode = `data:image/png;base64,${res.data}`;
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    confirm() {
      this.$refs.appealTb.getList(true);
    },
  },
};
</script>

<style lang='scss' scoped>
.warn {
  padding: 20px;
  background: #fff;
  font-size: 13px;
  .nav {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    .tip {
      margin-bottom: 20px;
    }
  }
}
.code-img {
  max-width: 100px;
}
</style>
