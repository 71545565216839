<!--
 * @Author: your name
 * @Date: 2022-03-30 11:34:27
 * @LastEditTime: 2022-04-02 09:50:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nbh_gys_acadmin\src\views\store\components\AppealModal.vue
-->
<!--
 * @Author: your name
 * @Date: 2022-03-30 09:10:43
 * @LastEditTime: 2022-03-30 11:06:03
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nbh_gys_acadmin\src\views\store\components\WarnDetailModal.vue
-->

<template>
  <el-dialog
    title="违规申诉"
    :visible.sync="value"
    width="60%"
    :before-close="handleClose"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item
        v-if="showIllegalId"
        prop="violationDetailNo"
        label="申诉违规ID"
      >
        <el-input
          v-model="form.violationDetailNo"
          :disabled="type == 1"
        />
      </el-form-item>
      <el-form-item
        prop="content"
        label="申诉描述"
      >
        <el-input
          v-model="form.content"
          type="textarea"
          :disabled="type == 1"
          :rows="2"
          placeholder="请输入内容"
        />
      </el-form-item>
      <el-form-item
        prop="imageUrls"
        label="申诉材料"
      >
        <el-upload
          :disabled="type == 1"
          :on-success="uploadSuc"
          :file-list="uploadList"
          accept="image/*"
          name="multipartFile"
          :action="$api.nb_common.newUpload"
          list-type="picture-card"
        >
          <i
            slot="default"
            class="el-icon-plus"
          ></i>
          <div
            slot="file"
            slot-scope="{ file }"
          >
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <!-- <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
            </span> -->
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item
        prop="mobile"
        label="联系方式"
      >
        <el-input
          v-model="form.mobile"
          :disabled="type == 1"
          placeholder="请输入内容"
        />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="$emit('input', false)">
        取消
      </el-button>
      <el-button
        v-if="type == 0"
        :loading="saveLoad"
        type="primary"
        @click="confirm"
      >
        提交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
      require: true,
    },
    row: {
      default: null,
    },
    type: {
      default: 0,
    },
  },
  data() {
    return {
      form: {
        content: '',
        imageUrls: '',
        mobile: '',
        supplierId: '',
        videoUrl: '',
        violationAlarmId: '',
        violationDetailNo: '',
        violationStatisticsId: '',
      },
      uploadList: [],
      saveLoad: false,
      rules: {
        violationDetailNo: [
          { required: true, message: '请输入违规ID', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入申诉描述', trigger: 'blur' },
        ],
        imageUrls: [
          { required: true, message: '请上传申诉材料', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
        ],
      },
      imgArr: [],
    };
  },
  computed: {
    showIllegalId() {
      const userInfo = JSON.parse(localStorage.userInfo || '{}');
      const qrColor = userInfo.qrCodeColor;
      if (!this.row) return false;

      if (qrColor != 1) {
        if (this.row.transtionType == 0) {
          // 按数量
          if (
            !eval(
              `${this.row.statisticsCount}${this.row.transtionSymbol}${this.row.transtionThreshold}`,
            )
          ) {
            return true;
          }
        }
      }

      return false;
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.type == 0) {
          // 新增
          this.initData();
        } else {
          // 详情
          this.getDetail();
        }
      }
    },
  },
  methods: {
    getDetail() {
      this.$axios
        .get(this.$api.storeWarn.appealDetail, { params: { id: this.row.id } })
        .then((res) => {
          if (res.code === 0) {
            for (let key in this.form) {
              this.form[key] = res.data[key];
            }

            this.uploadList = res.data.imageUrls.split(',').map((e, i) => {
              return {
                url: e,
                uid: i,
              };
            });
          } else {
            this.$message.success(res.msg);
          }
        });
    },
    initData() {
      const { supplierId, violationAlarmId, id } = this.row;
      for (let key in this.form) {
        this.form[key] = '';
      }
      this.uploadList = [];
      this.imgArr = [];
      this.form.supplierId = supplierId;
      this.form.violationAlarmId = violationAlarmId;
      this.form.violationStatisticsId = id;
    },
    uploadSuc(res) {
      console.log(this.uploadList);
      if (res.code === 0) {
        this.imgArr.push(res.data.fileUrl);
      }
    },
    confirm() {
      if (!this.imgArr.length) {
        this.$message.warning('请上传照片');
        return;
      }
      this.saveLoad = true;
      this.form.imageUrls = this.imgArr.join();
      this.$axios.post(this.$api.storeWarn.addAppeal, this.form).then((res) => {
        this.saveLoad = false;
        if (res.code !== 0) return;
        this.$message.success('提交成功');
        this.$emit('input', false);
        this.$emit('confirm');
      });
    },
    handleClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style>
</style>
