
<template>
  <el-pagination
    background
    :page-sizes="[10, 20, 30, 50]"
    :current-page="currentPage"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    @size-change="val => $emit('size-change', val)"
    @current-change="val => $emit('current-change', val)"
  >
  </el-pagination>
</template>

<script>
export default {
    props: {
        currentPage: {
            default: 1
        },
        pageSize: {
            default: 10
        },
        total: {
            default: 0
        }
    }
};
</script>

<style>
</style>
