<template>
  <div>
    <el-table
      v-loading="tbLoad"
      :data="tbList"
      border
      style="width: 100%"
      highlight-current-row
      :header-cell-style="{
        background: '#D9DDE1',
        color: '#0D0202',
        'font-weight': 400,
        fontSize: '12px',
      }"
    >
      <el-table-column
        align="center"
        prop="violationItem"
        label="违规项目"
      />
      <el-table-column
        align="center"
        prop="violationName"
        label="违规场景"
      />
      <el-table-column
        min-width="200"
        align="center"
        prop="description"
        label="定义"
      />
      <el-table-column
        align="center"
        prop="transtionDescription"
        :label="code === '3300' ? '转码规则' : '转标规则'"
      />

      <el-table-column
        align="center"
        prop="stockQuantity"
        label="违规进度"
      >
        <template slot-scope="scope">
          <div>
            {{
              scope.row.transtionType == 0
                ? scope.row.statisticsCount
                : Math.floor(scope.row.statisticsCount * 100)
            }}
            {{ scope.row.transtionType == 0 ? "单" : "%" }}/{{
              scope.row.transtionType == 0
                ? scope.row.transtionThreshold
                : Math.floor(scope.row.transtionThreshold * 100)
            }}
            {{ scope.row.transtionType == 0 ? "单" : "%" }}
          </div>
          <el-progress
            :color="customColor"
            :percentage="scope.row.percentage"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="violationAlarmLevel"
        label="违规等级"
      >
        <template slot-scope="scope">
          {{ colorDic[scope.row.violationAlarmLevel] }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="_showWarnDetailModal(scope.row)"
          >
            详情
          </el-button>
          <el-button
            v-if="showAppeal(scope.row)"
            type="text"
            size="mini"
            @click="_showAppealModal(scope.row)"
          >
            申诉
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="page.current"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <WarnDetailModal
      :id="rowId"
      v-model="showWarnDetailModal"
    />

    <!-- 申诉弹框 -->
    <AppealModal
      v-model="showAppealModal"
      :row="currentRow"
      :type="appealType"
      @confirm="$emit('confirm')"
    />
  </div>
</template>

<script>
import WarnDetailModal from './WarnDetailModal.vue';
import AppealModal from './AppealModal.vue';
import Page from '@/components/common/Page.vue';

export default {
  components: {
    Page,
    WarnDetailModal,
    AppealModal,
  },
  props: {
    code: {
      type: String,
      default: '3300',
    },
  },
  data() {
    return {
      customColor: [
        { color: '#409EFF', percentage: 60 },
        { color: '#f56c6c', percentage: 100 },
      ],
      tbLoad: false,
      tbList: [],
      colorDic: {
        0: '绿色',
        1: '红色',
        3: '黄色',
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      rowId: '',
      currentRow: null,
      userInfo: {},
      showWarnDetailModal: false,
      showAppealModal: false,
      appealType: 0,
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.userInfo || '{}');
  },
  mounted() {
    this.getList(true);
  },
  methods: {
    // 违规列表
    getList(isInit = false) {
      this.tbLoad = true;
      if (isInit) this.page.current = 1;

      const pars = {
        currentPage: this.page.current,
        endTime: '',
        pageSize: this.page.size,
        qrCodeColor: '',
        startTime: '',
        supplierId: '',
        suppliersName: '',
        violationItem: '',
      };

      this.$axios
        .post(this.$api.storeWarn.illegalList, pars)
        .then((res) => {
          if (res && res.code === 0) {
            if (res.data) {
              const {
                current, total, size, records,
              } = res.data;
              this.page = {
                current,
                total,
                size,
              };

              records.forEach((e) => {
                if (e.statisticsCount >= e.transtionThreshold) {
                  e.percentage = 100;
                } else {
                  e.percentage = Math.floor(
                    (e.statisticsCount / e.transtionThreshold) * 100,
                  );
                }
              });
              this.tbList = records;
            }
          } else {
            this.$message.error(res.msg);
          }
          this.tbLoad = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.tbLoad = false;
        });
    },
    _showWarnDetailModal(row) {
      this.rowId = row.id;
      this.showWarnDetailModal = true;
    },
    _showAppealModal(row) {
      this.currentRow = row;
      this.appealType = 0;
      this.showAppealModal = true;
    },
    // 判断能否申请
    showAppeal(row) {
      const qrColor = this.userInfo.qrCodeColor;
      if (qrColor == 1) {
        // 红色
        if (
          row.violationAlarmLevel == 1
          && row.isApprove == 0
          && eval(
            `${row.statisticsCount}${row.transtionSymbol}${row.transtionThreshold}`,
          )
        ) {
          return true;
        }
      } else {
        // 其他颜色
        if (
          row.violationAlarmLevel == 1
          && eval(
            `${row.statisticsCount}${row.transtionSymbol}${row.transtionThreshold}`,
          )
        ) {
          return false;
        }
        if (row.transtionType == 1) {
          // 按百分比
          if (
            row.violationAlarmLevel == qrColor
              && row.isApprove == 0
              && eval(
                `${row.statisticsCount}${row.transtionSymbol}${row.transtionThreshold}`,
              )
          ) {
            return true;
          }
        } else if (
          row.violationAlarmLevel == qrColor
              && eval(
                `${row.statisticsCount}${row.transtionSymbol}${row.transtionThreshold}`,
              )
        ) {
          if (row.isApprove == 0) {
            return true;
          }
        } else {
          return true;
        }
      }

      return false;
    },

    handleSizeChange(s) {
      this.page.size = s;
      this.getList();
    },
    handleCurrentChange(p) {
      this.page.current = p;
      this.getList();
    },
  },
};
</script>

<style>
</style>
