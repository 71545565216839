var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-pagination", {
    attrs: {
      background: "",
      "page-sizes": [10, 20, 30, 50],
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      layout: "total, sizes, prev, pager, next, jumper",
      total: _vm.total,
    },
    on: {
      "size-change": (val) => _vm.$emit("size-change", val),
      "current-change": (val) => _vm.$emit("current-change", val),
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }