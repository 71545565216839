var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "违规详情",
        visible: _vm.value,
        width: "60%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.value = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tbLoad,
              expression: "tbLoad",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            loading: _vm.tbLoad,
            data: _vm.tbList,
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "#D9DDE1",
              color: "#0D0202",
              "font-weight": 400,
              fontSize: "12px",
            },
            "row-key": "id",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", prop: "createTime", label: "违规时间" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "violationDetailNo",
              label: "违规ID",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "orderNo", label: "订单编号" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "goodsName", label: "商品名称" },
          }),
        ],
        1
      ),
      _c("Page", {
        attrs: {
          "current-page": _vm.page.current,
          "page-size": _vm.page.size,
          total: _vm.page.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }