var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "warn" },
    [
      _vm.userInfo.qrCodeColor != -1
        ? _c(
            "div",
            { staticClass: "nav" },
            [
              _c("el-alert", {
                staticClass: "tip",
                attrs: {
                  effect: "dark",
                  closable: false,
                  title:
                    _vm.userInfo.qrCodeColor == 1
                      ? "您的店铺存在严重违规行为,现已下架处理，请尽快完成整改，申请重新上架"
                      : _vm.userInfo.qrCodeColor == 0
                      ? "正常运营"
                      : "您的店铺存在违规行为请尽快完成整改",
                  type:
                    _vm.userInfo.qrCodeColor == 0
                      ? "success"
                      : _vm.userInfo.qrCodeColor == 1
                      ? "error"
                      : "warning",
                  "show-icon": "",
                },
              }),
              _c(
                "el-row",
                { attrs: { type: "flex", align: "middle", gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "tr",
                        staticStyle: {
                          "font-size": "15px",
                          "font-weight": "bold",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.projectCode === "3300"
                                ? "当前店铺码:"
                                : "当前店铺标识:"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm.projectCode === "3100"
                      ? _c("div", [
                          _vm.userInfo.qrCodeColor === 0
                            ? _c("img", {
                                staticClass: "code-img",
                                attrs: {
                                  src: "https://nb-img.hzanchu.com/h5/nbh_admin/img/20231108113740.png",
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm.userInfo.qrCodeColor === 3
                            ? _c("img", {
                                staticClass: "code-img",
                                attrs: {
                                  src: "https://nb-img.hzanchu.com/h5/nbh_admin/img/20231108113730.png",
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm.userInfo.qrCodeColor === 1
                            ? _c("img", {
                                staticClass: "code-img",
                                attrs: {
                                  src: "https://nb-img.hzanchu.com/h5/nbh_admin/img/20231108113750.png",
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.projectCode === "5100"
                      ? _c("div", [
                          _vm.userInfo.qrCodeColor === 0
                            ? _c("img", {
                                staticClass: "code-img",
                                attrs: {
                                  src: "https://oss.zjnongbo.com/common/images/ui/sansema-jiankang.png",
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm.userInfo.qrCodeColor === 3
                            ? _c("img", {
                                staticClass: "code-img",
                                attrs: {
                                  src: "https://oss.zjnongbo.com/common/images/ui/sansema-jinggao.png",
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm.userInfo.qrCodeColor === 1
                            ? _c("img", {
                                staticClass: "code-img",
                                attrs: {
                                  src: "https://oss.zjnongbo.com/common/images/ui/sansema-yichang.png",
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                        ])
                      : _c("img", {
                          staticClass: "code-img",
                          attrs: { src: _vm.qrCode, alt: "" },
                        }),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { margin: "20px 0" } },
        [
          _c(
            "el-radio-group",
            {
              attrs: { size: "medium" },
              model: {
                value: _vm.tbType,
                callback: function ($$v) {
                  _vm.tbType = $$v
                },
                expression: "tbType",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "0" } }, [
                _vm._v(" 违规情况 "),
              ]),
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v(" 申诉情况 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("IllegalTable", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tbType == 0,
            expression: "tbType == 0",
          },
        ],
        attrs: { code: _vm.projectCode },
        on: { confirm: _vm.confirm },
      }),
      _c("AppealTable", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tbType == 1,
            expression: "tbType == 1",
          },
        ],
        ref: "appealTb",
        attrs: { code: _vm.projectCode },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }