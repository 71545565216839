<!--
 * @Author: your name
 * @Date: 2022-03-30 09:10:43
 * @LastEditTime: 2022-04-08 11:46:54
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nbh_gys_acadmin\src\views\store\components\WarnDetailModal.vue
-->

<template>
  <el-dialog
    title="违规详情"
    :visible.sync="value"
    width="60%"
    :before-close="handleClose"
  >
    <el-table
      :loading="tbLoad"
      :data="tbList"
      v-loading="tbLoad"
      border
      style="width: 100%"
      highlight-current-row
      :header-cell-style="{
        background: '#D9DDE1',
        color: '#0D0202',
        'font-weight': 400,
        fontSize: '12px',
      }"
      row-key="id"
    >
      <el-table-column align="center"  prop="createTime" label="违规时间"> </el-table-column align="center">
      <el-table-column align="center" prop="violationDetailNo" label="违规ID"> </el-table-column align="center">
      <el-table-column align="center" prop="orderNo" label="订单编号"> </el-table-column align="center">
      <el-table-column align="center" prop="goodsName" label="商品名称"> </el-table-column>
    </el-table>
    <Page
      :current-page="page.current"
      :page-size="page.size"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </el-dialog>
</template>

<script>
import Page from "@/components/common/Page.vue";
import { dateFormat } from "@/utils/utils";
export default {
  props: {
    value: {
      default: false,
      require: true,
    },
    id: {
      default: "",
    },
  },
  data() {
    return {
      tbList: [],
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      tbLoad: false,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.tbList = [];
        this.getList(true);
      }
    },
  },
  methods: {
    getList(isInit = false) {
      this.tbLoad = true;
      if (isInit) this.page.currentPage = 1;

      const pars = {
        currentPage: this.page.current,
        endTime: "",
        pageSize: this.page.size,
        startTime: "",
        violationDetailNo: "",
        violationStatisticsId: this.id,
      };
      this.$axios.post(this.$api.storeWarn.illegalDetail, pars).then((res) => {
        const { current, total, size, records } = res.data;
        if (res.code === 0) {
          this.page = {
            current,
            total,
            size,
          };
          records.forEach(e => {
              e.createTime = dateFormat(+e.createTime);
          })
          this.tbList = records;
        } else {
          this.$message.error(res.msg);
        }
        this.tbLoad = false;
      });
    },
    handleClose() {
      this.$emit("input", false);
    },
    handleSizeChange(s) {
      this.page.size = s;
      this.getList();
    },
    handleCurrentChange(p) {
      this.page.current = p;
      this.getList();
    },
  },
  components: {
    Page,
  },
};
</script>

<style>
</style>
